import React from 'react';
import './Introduction.css';
import callouts from "../images/callouts.png";  

class Introuction extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          
        };
    }

    render(){
        return(
            <div className = 'intro-wrapper'>
                <div className = "intro-housing">
                    <div className = "intro-text-wrapper">
                        <div className = 'welcome'>Welcome to the Credit Score Desimulator™</div>
                        <div className = "simulator-introtext">

                        The Credit Score Desimulator™ marks a revolutionary departure from existing credit score
                        simulators. Rather than restricting ourselves to the bland financial factors credit scoring
                        companies claim to use, we take a holistic approach and include the things that really matter:
                        your behavior, your circumstances, and your social situation. <br></br><br></br>
                        

                        Based on the latest social science research, we do not rely on categories that in reality are proxies
                        for other social factors. Instead, we go straight to the source. The result is a more accurate but
                        also more transparent simulation. <br></br><br></br>

                        Go ahead, play around, and answer your credit score what-ifs. We do not have a crystal ball, but
                        neither does anyone else.
                        </div>
                        <div className ="callouts-holder">
                            <img src={callouts} className ='callouts' alt="Fancy Callout Things" />, 
                        </div>
                        <div className="scroll" onClick = {
                            () =>  window.scrollTo({
                                top: document.getElementsByClassName("intro-wrapper")[0].scrollHeight,
                                left: 0,
                                behavior: 'smooth'
                              })}>

                              </div>
                    </div>

                </div>
           </div>
        )
    }
} export default Introuction; 