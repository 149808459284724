import React from 'react';
import './BlurbPopup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";

class BlurbPopup extends React.Component{
    constructor(props){
        super(props);
    }


    render(){
        return(
            <div className = "modal">
                <div className = "modal-title">Why does this change my score?</div>
                 <div className = "modal-text">{this.props.text}</div>
                 <div className = "x" onClick = {this.props.closePopup}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                 </div>
            </div>

        );
    }
} export default BlurbPopup; 