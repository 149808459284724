import { render } from '@testing-library/react';
import React from 'react';
import Slider from './Slider/Slider.js';
import Input from './Input/Input.js';

import './InputsHousing.css';

class InputHousing extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            inputWrong: false
        }
    }

    inputType = this.props.inputType
    value = this.props.startValue

   


    

    renderSubmit(){
        return(
            <div>
            <button 
                onClick={() => {
                    if(isNaN(this.value)){
                        this.setState({inputWrong: true})
                    }
                    else {
                        this.setState({inputWrong: false})
                        this.props.submitScore(this.value)}} 
                    }
                className = "submit-button">
                 Submit
            </button>
            </div>
        )
    }

    renderSlider(){
        console.log(this.value); 
        return(
            <Slider
                value = {this.value}
                min = {this.props.min}
                max = {this.props.max}
                storeValue = {(value) => this.value = value}
            ></Slider>
        );
    }

    renderInputBox(){
        return(
            <Input
                value = {this.value}
                storeValue = {(value) => this.value = value}
            ></Input>
        );
    }

    renderCheckBox(){

    }

    renderClickOnly(){

    }

    render(){
        var inputToRender = (<div></div>);
        if (this.inputType == 'slider'){
            inputToRender = this.renderSlider();
        }
        else if (this.inputType == 'inputText'){
            inputToRender = this.renderInputBox();
        }
        else if (this.inputType == 'consent'){
            this.value = 1; 
        }
            return(
                <div className='input-housing'>
                    <div className = 'input-title'>What might happen if I {this.props.title}?</div>
                    <div className='input-info'>
                        <div className='input-icon'>{this.props.icon}</div>
                        <div className='input-text'>{this.props.text}</div>
                    </div>
                    {inputToRender}
                    {this.renderSubmit()}
                    {this.state.inputWrong && <div className = 'wrong-text'>Please input a number</div>}
                </div>
            );
        return(
            <div>

            </div>
        ); 
    };

}export default InputHousing; 