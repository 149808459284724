import React from 'react';
import './Factor.css';

class Factor extends React.Component{
    render(){
        return(
            <div className = "factor"
            onClick={() => this.props.onClick()}>
                <div className = "factor-icon-holder">
                    {this.props.icon}
                </div> 
                <div className = "factor-title">
                    {this.props.title}
                </div>
            </div>
        )
    }
}export default Factor; 