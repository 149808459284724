import React from 'react';
import './Input.css';

class Input extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            value: this.props.startValue
        };
    }

    handleChange = (e) => {
        this.setState({value: e.target.value});
        this.props.storeValue(e.target.value);
      };


    valuetext = (value) => {
        return `$${value}`;
    }

    render(){
        return(
            <div className = "input-housing">
                <input type="text" onChange={this.handleChange} value={this.state.value}  />
            </div>
        )
    }
} export default Input; 