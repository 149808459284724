import React from 'react';
import ScoreDisplay from "../ScoreDisplay/ScoreDisplay.js";
import FAQS from "../FAQ/FAQs.js";
import Footer from "../Footer/Footer.js";
import "./Simulator.css"; 
import FactorBox from '../Factors/FactorBox/FactorBox.js';
import Choices from '../Factors/Choices/Choices.js';
import InputHousing from '../Inputs/InputsHousing.js';
import Introduction from '../Introduction/Introduction.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import BlurbPopup from '../BlurbPopup/BlurbPopup.js';
import CloseButton from '../xButton/xButton.js';

/*importing images/icons */
import flu from "../images/flu.png";
import mistake from "../images/mistake.png";
import aid from "../images/aid.png";
import seminar from "../images/seminar.png";
import age from "../images/age.png";
import parents from "../images/parents.png";
import mortgage from "../images/mortgage.png";
import cards from "../images/cards.png";
import married from "../images/married.png";
import personality from "../images/personality.png";
import store from "../images/retailstore.png";
import onCampus from "../images/campus.png";
import move from "../images/move.png";
import mail from "../images/mailapplication.png";
import bank from "../images/bank.png";
import studentdebt from "../images/studentdebt.png";
import poorscore from "../images/poorscore.png"; 
import shopping from "../images/shopping.png"; 
import algorithm from "../images/algorithm.png"; 
import parentstalk from "../images/parentstalk.png"
import trustworthy from "../images/trustworthy.png";
import futurepreference from "../images/futurepreference.png"; 
import idtheft from "../images/idtheft.png"; 
import children from "../images/children.png";
import adopted from "../images/adopted.png";
import recession from "../images/recession.png"; 
import subprime from "../images/subprime.png";
import discount from "../images/discount.png"; 
import premium from "../images/premium.png";
import hospitalized from "../images/hospitalized.png";
import recovered from "../images/recovered.png"; 
import divorced from "../images/divorced.png"; 
import noaid from "../images/noaid.png";
import manager from "../images/manager.png";  

const initialFactorsState = [-1, -1, -1, -1] /* this vector represents what factor is selected: i is the category of factor,
j is the factor chosen, k is the sub-selection, and l is the input value (could take on many things, but integerized) */


class Simulator extends React.Component{
    constructor(props){
        super(props);
        this.onFactorClick = this.onFactorClick.bind(this);
        this.onOptionClick = this.onOptionClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.close = this.close.bind(this); 
        
        this.state =
        {   
            factorsState: initialFactorsState,
            score: 700, 
            lastScoreChange: 0,
            scoreSubmitted: false,
            blurb: false
        };
    }

    /* This array includes a list of all of our facots */
    factorsList = [
        {
            catKey: 0, 
            title: "Open or Close an Account",
            factors: [{
                factKey: 0,
                title: "Open a new Credit Card",
                icon: <img src={cards} className = 'factor-icon' alt="Credit Cards" />,
                hasSelections: true,
                selections: [
                    {
                        title: "At the bank",
                        icon: <img src={bank} className = 'option-icon' alt="Bank" />,
                        selectKey: 0,
                        inputText: 'Use the input box below to enter how much your credit limit would increase with the new card, in dollars.',
                        changeText: "Getting a card at a bank...",
                        blurbText: ""
                    },
                    {
                        title: "At the mall",
                        icon: <img src={store} className = 'option-icon' alt="Mall/Store" />,
                        selectKey: 1,
                        inputText: 'Use the input box below to enter how much your credit limit would increase with the new card, in dollars.',
                        changeText: "Getting a card at a mall...",
                        blurbText: "Studies have shown that students who acquired credit cards via the mail, at a retail store, or at an on-campus " + 
                        "solicitation were more likely to have more debt for a given income, be delinquent on accounts, and pay off " +
                        "their balances less frequently. Economist Angela Lyons (2005), for example, hypothesizes that aggressive " + 
                        "marketing practices from these companies may contribute to debt. This could be because these practices push " + 
                        "credit cards on those who are not ready and are unable to properly manage debt. We can further speculate " + 
                        "that getting a credit card at a retail store means one will likely use the card primarily for continuous retail " + 
                        "purchases, which could increase debt."
                    },
                    {
                        title: "Through the mail",
                        icon: <img src={mail} className = 'option-icon' alt="Mail" />,
                        selectKey: 2,
                        inputText: 'Use the input box below to enter how much your credit limit would increase with the new card, in dollars.',
                        changeText: "Getting a card through the mail...",
                        blurbText: "Studies have shown that students who acquired credit cards via the mail, at a retail store, or at an on-campus " + 
                        "solicitation were more likely to have more debt for a given income, be delinquent on accounts, and pay off " +
                        "their balances less frequently. Economist Angela Lyons (2005), for example, hypothesizes that aggressive " + 
                        "marketing practices from these companies may contribute to debt. This could be because these practices push " + 
                        "credit cards on those who are not ready and are unable to properly manage debt. We can further speculate " + 
                        "that getting a credit card at a retail store means one will likely use the card primarily for continuous retail " + 
                        "purchases, which could increase debt."
                    }
                ],
                inputType: 'inputText'
            },
            {
                factKey: 1,
                title: "Get a New Loan",
                icon: <img src={mortgage} className = 'factor-icon' alt="Mortgage" />, 
                hasSelections: true,
                selections: [
                    {
                        title: "From a subprime lender",
                        icon: <img src={subprime} className = 'option-icon' alt="Lender" />,
                        selectKey: 0,
                        inputText: 'Use the input box below to enter how much your credit limit would increase with the new loan, in dollars.',
                        changeText: "Getting a loan from a loan shark can cause you to sink further into debt.",
                        blurbText: "The characteristics of a loan, including who provides it, " +
                        "impact repayment. One study found that even for borrowers with similar characteristics, default rates are 4 to " + 
                        "5 times higher for subprime loans. Loans may have prepayment penalties, which discourage a borrower from " + 
                        "paying a loan off early (and then paying less in interest) or adjustable interest rates, which typically means the " + 
                        "interest rate rises over time, making budgeting and repayment potentially more difficult. Also, credit card " + 
                        "companies such as American Express have used mortgage lender type to set costumers’ credit limits, which " + 
                        "influence credit utilization (how much of your available credit you are using), a well-documented factor in " + 
                        "credit scores."
                    },
                    {
                        title: "From a bank",
                        icon: <img src={bank} className = 'option-icon' alt="Bank" />,
                        selectKey: 1,
                        inputText: 'Use the input box below to enter how much your credit limit would increase with the new loan, in dollars.',
                        changeText: "Getting a loan from a bank could make you appear a more reputable loan owner.",
                        blurbText: ""
                    },
                ],
                inputType: 'inputText'
            },
            {
                factKey: 2,
                title: "Have my Student Loan Debt Cancelled",
                icon: <img src={studentdebt} className = 'factor-icon' alt="Student Debt" />,
                hasSelections: false,
                inputText: 'Use the button below to see what would happen if my student loans were canceled.',
                changeText: "Student loans can increase your debt.",
                blurbText: "Student loan debt, especially that large enough to qualify for " + 
                "cancellation, likely negatively impacts your credit score. Experian admits that student loans are treated as any " + 
                "other debt, and thus can make it difficult to quality for further loans you may need to improve your score. " + 
                "Debt also reduces your available funds needed to withstand other finanical pressures, which may decrease " + 
                "your score. And, any late payments on student loan debt directly, negatively impact credit score. Having your " + 
                "student debt cancelled removes these negative drags on your score, and it would therefore increase. ", 
                inputType: "consent"
            }, 
            {
                factKey: 3,
                title: "Have a score too low to apply for a credit card",
                icon: <img src={poorscore} className = 'factor-icon' alt="Low Score" />,
                hasSelections: false,
                inputText: "Use the button below to see what would happen if I have a score too low to apply for a credit card.",
                changeText: "By having a score too low to apply for a new credit card or loan, you will be unable to aquire the necessary funds to escape debt.",
                blurbText: "When you don’t have access to mainstream lenders,  " + 
                "such as banks, you are relegated to a subprime lending market, where you face exorbitantly high loans and "+
                "requirements to fork over more data. Payday lenders, which provide short terms loans at extremely high rates, " + 
                "are an example. Wonga, a former British payday lender, used data points such as browser type, IP address, "+ 
                "and even sought Facebook connections to make lending decisions. Moreover, according to one analysis ," + 
                "payday lenders typically only include negative information, such as defaults and late payments, and omit " + 
                "information on loan repayments in reports to credit bureaus. Being forced into the subprime market, then, " + 
                "either because of lower credit scores or lack of available mainstream lenders because of poor socioeconomic " + 
                "status of a neighborhood, can mean being trapped in a cycle of damaging credit events.",
                inputType: "consent"
            }]
        },
        {
            catKey:1, 
            title: "Change how I use my cards?",
            factors: [{
                factKey: 0,
                title: "Change my shopping preferences",
                icon: <img src={shopping} className = 'factor-icon' alt="Shopping Preferences" />,
                hasSelections: true,
                selections: [
                    {
                        title: "Preferred Discount Stores",
                        icon: <img src={discount} className = 'option-icon' alt="Discount Card" />,
                        selectKey: 0,
                        inputText: 'Use the button below to see what would happen if I prefered to shop at discount stores.',
                        changeText: "Our propreitary method shows that shoppers who rely on discount stores...",
                        blurbText: "Credit card companies such as American " + 
                        "Express have used customers’ shopping habits to set credit limits. They determine the stores at which " + 
                        "cardholders with poor repayment histories shop. If you prefer the same stores, your credit card company may " + 
                        "lower your credit limit. Since this reduces the credit you have available, your credit utilization (how much of " + 
                        "your available credit you are using), may increase, which would negatively influence your credit score."
                    },
                    {
                        title: "Preferred Premium Brands",
                        icon: <img src={premium} className = 'option-icon' alt="Diamond in hand" />,
                        selectKey: 1,
                        inputText: 'Use the button below to see what would happen if I shopped premium brands.',
                        changeText: "Our propreitary method shows that shoppers who buy premium brands",
                        blurbText: "Credit card companies such as American " + 
                        "Express have used customers’ shopping habits to set credit limits. They determine the stores at which " + 
                        "cardholders with poor repayment histories shop. If you prefer the same stores, your credit card company may " + 
                        "lower your credit limit. Since this reduces the credit you have available, your credit utilization (how much of " + 
                        "your available credit you are using), may increase, which would negatively influence your credit score."
                    },
                ],
                inputType: 'consent'
            },
            {
                factKey: 1,
                title: "Have a lender use a different credit scoring algorithm",
                icon: <img src={algorithm} className = 'factor-icon' alt="Credit scoring Algorithm" />, 
                hasSelections: false,
                inputText: 'Use the button below to see what would happen if I go to a lender that uses a different credit scoring algorithm.',
                changeText: "Different credit scoring algorithms can consider wildly different factors, so...",
                blurbText: "There is more than just one credit score. " + 
                "The two major players in credit scoring, FICO and VantageScore, have released multiple scoring models over " + 
                "time, and these models weigh certain factors differently. For example, FICO 5, which is prevalent in auto and " + 
                "mortgage lending is less forgiving of unpaid collections accounts and medical debt than the newer and more " + 
                "common FICO 8. Moreover, moneylenders have industry specific scoring models that may differ from base " + 
                "models. There are versions of FICO 5 designed for mortgages, cars, and credit cards. So, there are many base "+ 
                "models and varying industry models originating from each base model, meaning that the credit scores a lender " + 
                "actually uses will be different from those used by others or reported on a credit monitoring service (e.g. " + 
                "Credit Karma).Since the models remain proprietary, it is impossible to know how changing the model affects " + 
                "your score. It could go up or could go down if a different model is used.",
                inputType: "consent"
            }]
        },
        {
            catKey:2, 
            title: "Change My Payment Habits",
            factors: [{
                factKey: 0,
                title: "Took a seminar on healthy credit practices",
                icon: <img src={seminar} className = 'factor-icon' alt="seminar" />,
                inputText: 'Use the button below to see what would happen if I took a seminar on healthy credit practices.',
                changeText: "",
                hasSelections: false,
                blurbText: "Taking a seminar on credit use reduced financially risky " + 
                "behavior among participants in a study. Better financial and credit management practices likely promote " + 
                "better management of debt, which would lead to an overall increase in credit score.",
                inputType: "consent"
            },
            {
                factKey: 1,
                title: "Talk to my parents about good financial habits",
                icon: <img src={parentstalk} className = 'factor-icon' alt="Talking to Parents" />,
                hasSelections: false,
                inputText: 'Use the button below to see what would happen if I talk to my parents about good financial habits.',
                changeText: "",
                blurbText: "One study found that financial information " + 
                "provided by parents to college-age children reduced risky and unwise credit card uses. These habits could " + 
                "drive young people into debt early, which would lower their credit score immediately but also have prolonged " + 
                "impacts on their ability to transact in the credit market, leading to compounding, score-lowering events. " + 
                "Alternatively, wise credit practices beginning early allows young individuals to establish solid credit " + 
                "foundations and grow their score over time.",
                inputType: "consent"
            },
            {
                factKey: 2,
                title: "Were in a more managerial or professional role",
                inputText: 'Use the button below to see what would happen if i were in a more managerial or professional role.',
                icon: <img src={manager} className = 'factor-icon' alt="Manager Icon" />,
                changeText: "",
                hasSelections: false,
                blurbText: "One study found that being in a professional and/or "+
                "managerial occupation predicted higher installment loans and outstanding credit card debt, which would " + 
                "decrease credit scores.",
                inputType: "consent"
            },
            {
                factKey: 3,
                title: "Seemed more trustworthy?",
                icon: <img src={trustworthy} className = 'factor-icon' alt="Trustworthy" />,
                inputText: 'Use the button below to see what would happen if people thought I was more trustworthy.',
                changeText: "",
                hasSelections: false,
                blurbText: "One study found a relationship between measures of trustworthiness, impulsivity, " + 
                "and patience (future time preference) and credit score. Higher scored trustworthiness and future time " + 
                "preference were linked to a higher credit score, while higher rated impulsivity correlated with a lower score. " + 
                "While the study that found this does not deeply explore why such a relationship exists, these personality traits " + 
                "could affect credit score in a number of ways. " + 
                "Trustworthy individuals may value repayment more, placing it above other expenses in times of distress, and " + 
                "thus have a more positive repayment history, which is an important factor in credit scoring, according to " + 
                "FICO. Another possibility is that trustworthy individuals may have an easier time securing loans on favorable " +
                "terms, if they speak with a lender directly. This also would improve repayment and decrease amounts owed, " + 
                "since a loan with more favorable terms is easier to repay. Trustworthy individuals may also value holding onto " + 
                "one card rather than frequently changing cards, which would increase the age of their accounts (a factor " + 
                "considered in credit score)",
                inputType: "consent"
            },
            {
                factKey: 4,
                title: "Cared a little more about the future?",
                icon: <img src={futurepreference} className = 'factor-icon' alt="Future Time Preference" />,
                inputText: 'Use the button below to see what would happen if I cared a little more about the future and my behavior was a bit more future-focused.',
                changeText: "",
                blurbText: "One study found a relationship between measures of trustworthiness, " + 
                "impulsivity, and patience (future time preference) and credit score. Higher scored trustworthiness and future " + 
                "time preference were linked to a higher credit score, while higher rated impulsivity correlated with a lower " + 
                "score. While the study that found this does not deeply explore why such a relationship exists, these " +
                "personality traits could affect credit score in a number of ways. " + 
                "Future time preference could lead to consumers avoiding risky loans; they value the current money provided " + 
                "by the loan less than the task of repaying, with interest. This could potentially decrease unmanageable debt " + 
                "and keep credit scores high.", 
                hasSelections: false,
                inputType: "consent"
            }
            ]
        },
        {
            catKey:3, 
            title: "Get New Negative Marks",
            factors: [{
                factKey: 0,
                title: "Come Down with the Flu",
                icon: <img src={flu} className = 'factor-icon' alt="flu" />,
                hasSelections: true,
                selections: [
                    {
                        title: "Recover quickly",
                        icon: <img src={recovered} className = 'option-icon' alt="healthy" />,
                        selectKey: 0,
                        inputText: 'Use the button below to see what would happen if I get the flu, but recover quickly.',
                        changeText: "",
                        blurbText: "Health shocks can negatively impact credit score. One " +
                       "study demonstrates a link between influenza outbreaks and credit card and mortgage defaults. The study " + 
                        "briefly discusses this relationship, and we can speculate along similar lines that such a health shock may " +
                        "temporarily take people out of work (to recover or care for a family member) and require unforeseen " + 
                        "expenses for care. This financial shock could lead to falling behind on credit card payments, exacerbating " + 
                        "existing debt issues or creating new ones. Resulting defaults with bring down your credit score. Furthermore, " + 
                        "a recent survey showed that medical debt caused 1 in 3 cardholders to go into credit card debt, illustrating " + 
                        "that health shocks can directly increase credit card balances and drag down credit scores."
                    },
                    {
                        title: "Become hospitalized",
                        icon: <img src={hospitalized} className = 'option-icon' alt="in hopsital bed" />,
                        selectKey: 1,
                        inputText: 'Use the button below to see what would happen if I get the flu, but have to stay in the hospital.',
                        changeText: "",
                        blurbText: "Health shocks can negatively impact credit score. One " +
                        "study demonstrates a link between influenza outbreaks and credit card and mortgage defaults. The study" + 
                         "briefly discusses this relationship, and we can speculate along similar lines that such a health shock may" +
                         "temporarily take people out of work (to recover or care for a family member) and require unforeseen " + 
                         "expenses for care. This financial shock could lead to falling behind on credit card payments, exacerbating  " + 
                         "existing debt issues or creating new ones. Resulting defaults with bring down your credit score. Furthermore, " + 
                         "a recent survey showed that medical debt caused 1 in 3 cardholders to go into credit card debt, illustrating " + 
                         "that health shocks can directly increase credit card balances and drag down credit scores."
                    },
                ],
                inputType: 'consent'
            },
            {
                factKey: 1,
                title: "Discover a mistake on my credit report",
                icon: <img src={mistake} className = 'factor-icon' alt="Mistake on Card" />,
                inputText: 'Use the button below to see what would happen if I discover a mistake on my credit report.',
                changeText: "",
                blurbText: "A 2012 report by the FTC documented that up to 1 in 5 " + 
                "consumers had errors on their credit reports; 92% of consumers who disputed their report and saw their " + 
                "score change had an increase in score. So, finding an error on your credit report probably means your score is " + 
                "lower than it should be. The FTC also notes that the most common errors are with collections information " + 
                "and consumer accounts. Since having accounts in collections or with large outstanding balances would likely " + 
                "bring down credit scores, one can speculate this is why errors on reports typically result in a lower, rather than " + 
                "higher, score",
                hasSelections: false,
                inputType: "consent"
            },
            {
                factKey: 2,
                title: "Have my identity stolen",
                icon: <img src={idtheft} className = 'factor-icon' alt="Identity Theft" />,
                inputText: 'Use the button below to see what would happen if I have my identity stolen.',
                changeText: "",
                blurbText: "Equifax explains that being a victim of identity theft is likely to reduce scores. " + 
                "This is because thieves can open credit cards or lines of credit in your name and fail to pay the bills, incurring " + 
                "new negative marks and poor repayment history in your name. It can be difficult to establish that these were " + 
                "not your actions, and as a result, identity theft can drag down your credit score. ",
                hasSelections: false,
                inputType: "consent"
            }]
        },
        {
            catKey:4, 
            title: "Expereince a Change in Life Circumstances",
            factors: [{
                factKey: 0,
                title: "Grow older",
                icon: <img src={age} className = 'factor-icon' alt="Age" />,
                inputText: 'Use the slider below to enter how many years older you will become.',
                changeText: "",
                blurbText: "The older a consumer is, the higher their score tends to be. Studies have documented this, and " + 
                "Experian, one of the three major credit bureaus, notes this fact in an article. It explains that this rise may be a " + 
                "result of rising household income with age, which enables those with debt to pay it off, decreasing credit " + 
                "utilization (how much of your available credit you are using). However, the increase is not constant. From the " + 
                "40s, to 50s, to 60s, credit scores increase at a faster rate, while from the 20s to 30s, credit scores go up, but " +
                "only slightly. Experian theorizes this is because average debt is still increasing among those age groups but is " +
                "typically reduced across all categories by consumers as they enter their 50s.",
                hasSelections: false,
                inputType: "slider",
                min: 1,
                max: 20
            },
            {
                factKey: 1,
                title: "Get married or divorced",
                icon: <img src={married} className = 'factor-icon' alt="Get Married" />, 
                hasSelections: true,
                min: 300,
                max: 850,
                selections: [
                    {
                        title: "Get married",
                        icon: <img src={married} className = 'option-icon' alt="married" />,
                        selectKey: 0,
                        inputText: 'Enter your spouse\'s credit score below',
                        changeText: "",
                        blurbText: "When applying for a mortgage, lenders check both your scores and that of your spouse. Since " + 
                        "there are three credit bureaus that collect credit information, the lender will look at the FICO scores " + 
                        "calculated based on each bureau’s information and take the median of that score. Then, the lender will pick " + 
                        "the lower of the two median scores to use in the mortgage application. So, if you spouse’s credit scores are " + 
                        "higher than yours, your credit score will be used, so there is no effect on your score. If you spouse’s credit " + 
                        "score is lower, however, you chance of being approved for certain loans, especially mortgages, will decrease, " +
                        "effectively lowering your credit score."
                    },
                    {
                        title: "Get divorced",
                        icon: <img src={divorced} className = 'option-icon' alt="rings breaking, divorce" />,
                        selectKey: 1,
                        inputText: 'Enter your (ex-)spouse\'s credit score below',
                        changeText: "",
                        blurbText: "When applying for a mortgage, lenders check both your scores and that of your spouse. Since " + 
                        "there are three credit bureaus that collect credit information, the lender will look at the FICO scores " + 
                        "calculated based on each bureau’s information and take the median of that score. Then, the lender will pick " + 
                        "the lower of the two median scores to use in the mortgage application. So, if you spouse’s credit scores are " + 
                        "higher than yours, your credit score will be used, so there is no effect on your score. If you spouse’s credit " + 
                        "score is lower, however, you chance of being approved for certain loans, especially mortgages, will decrease, " +
                        "effectively lowering your credit score."
                    },
                ],
                inputType: 'slider'
            },
            {
                factKey: 2,
                title: "Move to a different neighborhood",
                icon: <img src={move} className = 'factor-icon' alt="Move" />,
                inputText: "Use the input box below to enter the Zip Code of your new address.",
                changeText: "",
                blurbText: "Those with excellent and very good credit scores were more likely to " + 
                "live in neighborhoods with a higher median income, higher levels of advanced education, and a higher " + 
                "proportion of residents in white collar jobs, according to one study. The study explains that an area with " + 
                "higher credit scores theoretically has access to more financial resources, such as loans at favorable rates, " + 
                "which we can speculate enables business development, funding for college, or mortgages for more expensive " + 
                "homes. Moreover, communities with such characteristics have better access to financial lenders with " + 
                "favorable rates (whereas low income communities will lack such resources), which could produce easier loans " + 
                "to repay. This decreases the risk of default and allows for health debt management, raising credit score. Also, " + 
                "American Express has used home prices in one’s area to set credit limits, which directly influence credit " + 
                "utilization (how much of your available credit you are using), a key factor in credit score (says FICO).",
                hasSelections: false,
                inputType: "inputText"
            }, 
            {
                factKey: 3,
                title: "Have more children",
                icon: <img src={children} className = 'factor-icon' alt="Children" />,
                inputText: 'Use the slider below to enter how many more children.',
                changeText: "",
                blurbText: "One study linked larger family size with higher outstanding credit card balances and " + 
                "installment debt. This could make managing debt more difficult, raising credit utilization and (how much of " + 
                "your available credit you are using) and increasingly the likelihood of default, which would bring credit score " + 
                "down. The study does not speculate about why this relationship exists; however, with more children, " + 
                "expenses could rise, and other loans could be required, such as a mortgage for a larger home or loans for " + 
                "college. The increased financial pressures of these loan payments may require a family to use their credit or " + 
                "installment loans for other purchases and prevent them from paying off their card balance in full every cycle " + 
                "(e.g. the mortgage may take precedence).",
                min: 1, 
                max: 10,
                hasSelections: false,
                inputType: "slider"
            },
            {
                factKey: 4,
                title: "Get adopted",
                icon: <img src={adopted} className = 'factor-icon' alt="Adoption" />,
                inputText: 'Enter your new parents\' average credit score',
                changeText: "",
                blurbText: " A study conducted by the Federal Reserve Bank of Chicago found that the credit scores of an " + 
                "individual are correlated with those of their parents. The study found that the credit scores of two individuals " + 
                "are predicted to differ by 44 points if those individuals’ parents’ scores differed by 100 points. Furthermore, " + 
                "they found that if an individuals’ parents were in the 25thpercentile of credit scores, that individual’s score is " + 
                "expected to be in the 40thpercentile. For an individual whose parents were in the 75thpercentile, the expected " +
                "percentile is the 60th. While the study does not go into why credit score persists between generations, a " + 
                "number of factors could play a role. If parents have higher credit scores, this could indicate higher financial " + 
                "knowledge or healthy credit management practices. Other studies indicate that the more credit card use " + 
                "information received from parents, the lower children’s outstanding credit card balances are. They may " + 
                "encourage these practices in and financially educate their children. Also, if parents have higher credit scores, " + 
                "they may have more access to favorable rates or financial resources. They could thus secure better loan terms " + 
                "for their children (such as in the case of student loans) or aid their children in paying off balances and keeping " +
                "debt low. Children can also join their parents’ card as an authorized user, which allows them to build credit " + 
                "risk-free. So, their children would see an increase in credit score resulting from positive repayment history and " + 
                "low amounts owed.",
                hasSelections: false,
                min: 300,
                max: 850,
                inputType: "slider"
            },
            {
                factKey: 5,
                title: "Undergo an economic recession",
                icon: <img src={recession} className = 'factor-icon' alt="Recession" />,
                hasSelections: true, 
                selections: [
                    {
                        title: "Get Government Aid",
                        icon: <img src={aid} className = 'option-icon' alt="government aid" />,
                        selectKey: 0,
                        inputText: 'Use the button below to see what would happen if you experience a recession but receive government aid.',
                        changeText: "",
                        blurbText:  "In times of economic downturns, government assistance may raise credit " + 
                        "scores. For example, during the COVID-19 pandemic and resulting economic slowdown, deferred payment " + 
                        "programs, which allowed borrowers to miss payments without penalty, and financial stimulus, which " + 
                        "provided more cash to potentially pay down bills, raised average credit scores in the country, as the Wall " + 
                        "Street Journal reported. Such government programs may be utilized in later recessions with a similar positive " + 
                        "effect on credit scores."
                    },
                    {
                        title: "Get No Aid",
                        icon: <img src={noaid} className = 'option-icon' alt="asking for money, no government aid" />,
                        selectKey: 1,
                        inputText: 'Use the button below to see what would happen if you experience a recession and get no aid from the government.',
                        changeText: "",
                        blurbText: "In times of economic downturns, government assistance may raise credit " + 
                        "scores. For example, during the COVID-19 pandemic and resulting economic slowdown, deferred payment " + 
                        "programs, which allowed borrowers to miss payments without penalty, and financial stimulus, which " + 
                        "provided more cash to potentially pay down bills, raised average credit scores in the country, as the Wall " + 
                        "Street Journal reported. Such government programs may be utilized in later recessions with a similar positive " + 
                        "effect on credit scores. But, without such programs, a recession likely brings increased financial pressure, along with " + 
                        "difficulty making credit card payments and accessing financial insitutions."
                    },
                ],
                inputType: "consent"
            },
        ]
        }
    ]

    factorsUpdateFormulas = [
        [{
            title: "Open a new card",
            formula: [
                (value) => Math.round(value/100),
                (value) => Math.round(-2 * value/100),
                (value) => Math.round(-1 * value/100)
            ], 
        },
        {
            title: "New loan",
            formula: [
                (value) => Math.round(-value/100),
                (value) => Math.round(value/100),
            ], 
        },
        {
            title: "Student loans",
            formula: (value) => value*100,
        },
        {
            title: "Credit Score Too Low", 
            formula: (value) => -value*100,
        }
        ],
        [{
            title: "Shopping preferences", 
            formula: [
                (value) => Math.round(-value * 100),
                (value) => Math.round(value * 100),
            ], 
        },
        {
            title: "Different algorithm", 
            formula: (value) => Math.round((Math.random() - .5)*value*100)
        },
        ],
        [{
            title: "Seminar",
            formula: (value) => value*100,
        },
        {
            title: "Good habits", 
            formula: (value) => value*100,
        },
        {
            title: "Managerial or professional", 
            formula: (value) => -1 * value*80,
        },
        {
            title: "Trust",
            formula: (value) => value*100,
        },
        {
            title: "Future", 
            formula: (value) => value*100,
        }],
        [   
            {
                title: "Flu", 
                formula: [
                    (value) => Math.round(-value * 50),
                    (value) => Math.round(-value * 100),
                ], 
            }, 
            { 
                title: "Mistake", 
                formula: (value) => Math.round((Math.random() - 1)*value*100)
            }, 
            { 
                title: "Identity Stolen", 
                formula: (value) => Math.round(-value*50)
            },
        ],
        [
            { 
                title: "Grow Older", 
                formula: (value) => Math.round(value*10)
            },
            {
                title: "Married or Divorced", 
                formula: [
                    (value) =>  Math.round((value - this.state.score)/2),
                    (value) =>  -Math.round((value - this.state.score)/2),
                ], 
            }, 
            {
                title: "Moved", 
                formula: (value) => Math.round((this.getIncome(value) - 68703) * .001) 
            }, 
            { 
                title: "Have more children", 
                formula: (value) => Math.round(-value*10)
            },
            { 
                title: "Adopted", 
                formula: (value) => Math.round((value - this.state.score)/2)
            },
            {
                title: "Recession", 
                formula: [
                    (value) =>  Math.round(value*100),
                    (value) =>  Math.round(-value*100),
                ], 
            }, 
        ]
    ]

    /*Deal with Zip Code Input*/ 

    httpGet(theUrl)
    {
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open( "GET", theUrl, false ); // false for synchronous request
            xmlHttp.send( null );
            return xmlHttp.responseText;
    }
    zipCodes = this.httpGet("https://api.census.gov/data/2018/acs/acs5/subject?get=NAME,S1903_C03_001E&for=zip%20code%20tabulation%20area:*"); 

    getIncome =(zipCode) => {
        let place = this.zipCodes.search("ZCTA5 " + zipCode);
        console.log(place);
        if (place == -1){
            return 68703;
        }
        let medianIncome = this.zipCodes.substring(place +14, place+20); 
        if (medianIncome.charAt(medianIncome.length-1) == '\"'){
            medianIncome = medianIncome.substring(0, medianIncome.length-1); 
        }
        if (medianIncome < 0){
            medianIncome = 68703;
        }
        return (medianIncome); 
    }



    /*===============================
    Functions
    =================================
    */

    /*This function is called when a factor is clicked to be selected*/
    onFactorClick(i, j){
        const factorsState = this.state.factorsState.slice(); 
        factorsState[0] = i;
        factorsState[1] = j;
        this.setState({factorsState: factorsState});
    }

    /*This function is called when a factor has selectors/options that need to be selected*/
    onOptionClick(k){
        const factorsState = this.state.factorsState.slice();
        factorsState[2] = k;
        this.setState({factorsState: factorsState});
    }

    /*This functoion is called when the submit button is checked in the input*/
    onSubmit(value){
        const factorsState = this.state.factorsState.slice()
        var scoreChange = 0
        if (factorsState[2] !== -1){
            scoreChange = this.factorsUpdateFormulas[factorsState[0]][factorsState[1]].formula[factorsState[2]](value)
        }
        else{
            scoreChange = this.factorsUpdateFormulas[factorsState[0]][factorsState[1]].formula(value)
        }

        var newScore = this.state.score + scoreChange;

        if(newScore > 850){
            newScore = 850;
            scoreChange = -this.state.score + newScore;
        }

        if(newScore < 300){
            newScore= 300
            scoreChange = -this.state.score + newScore;
        }


        this.setState({score: newScore, lastScoreChange: scoreChange, scoreSubmitted: true})
    }

    close(){
        this.setState({ factorsState: [-1, -1, -1, -1], scoreSubmitted: false, score: this.state.score-this.state.lastScoreChange, lastScoreChange: 0})
    }


    /* How to render the factors in pseudocode (What the below function does)
    Name/Style

        Factor:
        OptionaL: Sub-selection
        Input

    If nothing is selected, render the the box that displays all factors
        A click on one of these factors will shift to a window displaying that factor's subinputs 

    Else If a factor is clicked AND this factor does not have a sub-selection
        render input for factor 
        there is a submit box

    If a factor is clicked that has a sub-selection
        Render the sub-selection 

    
    If a facgtor is clicked and a sub-selection is clicked
        Render the the input for this sub-selection's input
        there is a submit box

    If a submit box has been clicked
        We stopped using this function altogether, and turn to a different one
        
    */

    renderFactors(){
        /* Begin by determining what is clicked, easy to do this using factorsState*/
        const factorsState = this.state.factorsState; 
        const clicked = factorsState[1] !== -1 

            /* if nothing is clicked, render the list of factors as a FactorBox */
        if (!clicked){
            return (
            <div>
                <div className = "factors-title">What might happen if I... </div>
                    <FactorBox 
                        boxName = "Open or Close an Account?"
                        factorsList = {this.factorsList} 
                        factorsState = {this.state.factorsState}
                        onFactorClick = {this.onFactorClick}
                    > </FactorBox>
            </div>); 

        }
        else {
            const selectedFactor = this.factorsList[factorsState[0]].factors[factorsState[1]]
            const clickedHasSelectors = selectedFactor.hasSelections; 

            var simStart = document.getElementsByClassName("intro-wrapper")[0];

            window.scrollTo({
                top: simStart.scrollHeight,
                left: 0,
                behavior: 'smooth'
              });

            /*If a factor is clicked and does not have a selector, render appropriate input window*/
            if (!clickedHasSelectors){
                /*Render input*/
                return (
                <div className = 'factors-sub-container'>
                    <CloseButton close = {this.close}></CloseButton>
                    <InputHousing
                        startValue = {Math.round(selectedFactor.min + selectedFactor.max/4)}
                        inputType = {selectedFactor.inputType}
                        text = {selectedFactor.inputText}
                        icon = {selectedFactor.icon}
                        min = {selectedFactor.min}
                        max = {selectedFactor.max}
                        submitScore = {this.onSubmit}
                        title = {selectedFactor.title.toLowerCase()} 
                    ></InputHousing>
                </div>);
            }
                /* If a factor is clicked and it has a selector*/
            else{
                /*and the selector is clicked, render the appropriate input window*/
                if (factorsState[2] !== -1){
                    const selectedSelection = selectedFactor.selections[factorsState[2]];
                    return (
                    <div className = 'factors-sub-container'>
                        <CloseButton close = {this.close}></CloseButton>
                        <InputHousing
                            startValue = {Math.round(selectedFactor.min + selectedFactor.max/4)}
                            inputType = {selectedFactor.inputType}
                            text = {selectedSelection.inputText}
                            icon = {selectedSelection.icon}
                            min = {selectedFactor.min}
                            max = {selectedFactor.max}
                            submitScore = {this.onSubmit}
                            title = {selectedFactor.title.toLowerCase() + " " + selectedSelection.title.toLowerCase()} 
                        ></InputHousing>
                    </div>);
                }
                /*Otherwise, render the appropriate selector window */
                else{
                    return(
                        <div className = 'factors-sub-container'>
                            <CloseButton close = {this.close}></CloseButton>
                            <div className = "factors-title">What might happen if I <b>{selectedFactor.title.toLowerCase()}?</b></div>
                            <Choices 
                                options = {selectedFactor.selections}
                                onOptionClick = {this.onOptionClick}>
                            </Choices> 
                        </div>
                        ); 
                }
            }
        }}

        scoreChangeRender(){
            const scoreChange = this.state.lastScoreChange;
            if (scoreChange > 0){

            }else if (scoreChange < 0){

            }else{
            }
        }

        renderScoreUpdateWindow(){
            const scoreChange = this.state.lastScoreChange;
            const factorsState = this.state.factorsState; 
            const selectedFactor = this.factorsList[factorsState[0]].factors[factorsState[1]] 
            if (factorsState[2] !== -1){
                const selectedSelection = selectedFactor.selections[factorsState[2]];
                var changeText = selectedSelection.changeText;
            }
            else{
                var changeText = selectedFactor.changeText; 
            }
            
            
            var scoreChangeSpan = <div>Your score went up by <span style={{color: 'green'}}>{scoreChange}</span>  points.</div>;
            if (scoreChange < 0){
                scoreChangeSpan = <div>Your score went down by  <span style={{color: 'red'}}>{-1*scoreChange}</span> points.</div>;
            }else if (scoreChange == 0){
                scoreChangeSpan = <div>Your score did not change.</div>;
            }

            console.log(scoreChangeSpan);

            
            return(
                <div className = 'score-update-housing'>
                   <CloseButton close = {this.close}></CloseButton>
                    <div className = 'score-update-text'>
                        {scoreChangeSpan}
                    </div>
                    <button className = "why-button" onClick={() => this.setState({blurb: true})}>
                        Why? 
                    </button>
                </div>
            )
        }

        getBlurbText(){
            const factorsState = this.state.factorsState; 
            const selectedFactor = this.factorsList[factorsState[0]].factors[factorsState[1]] 
            if (factorsState[2] !== -1){
                const selectedSelection = selectedFactor.selections[factorsState[2]];
                var blurbText = selectedSelection.blurbText;
            }else{
                var blurbText = selectedFactor.blurbText; 
            }

            return blurbText; 
        }


    /*Rendering*/ 
    render(){


        return(
        <div>
            <Introduction></Introduction>
            <div className = "simulator-container">

            <div className = "simulator-heading"> 
                        CREDIT SCORE <span className = "dis">DE</span>SIMULATOR™
            </div>
                
                <div className = "sticky-content">
                    <ScoreDisplay score={this.state.score} label = "Current Score"/>
                </div>
                {this.state.blurb && 
                    <BlurbPopup 
                        text={this.getBlurbText()} 
                        closePopup={() => this.setState({blurb: false})}>
                    </BlurbPopup>
                }
                <div className = "factors-container">
                    {!this.state.scoreSubmitted
                        ? this.renderFactors()
                        : this.renderScoreUpdateWindow()
                    }
                </div>
                <FAQS /> 
                <Footer /> 
            </div>
        </div>
        );
    }
} export default Simulator; 

