import "./Footer.css"; 
import React from 'react';

class Footer extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div className = "footer-container">
                <div className = "footer-text">
                    <div>To learn more about consumer credit scoring:  </div>
                    <div> Research studies used in building this tool: </div>
                </div>
                <div className = "footer-text-2">
                    <div><span className="bold"> Credits: </span> Mike Tyrrell, Malte Ziewitz</div>  
                    <div><span className="bold"> Funding: </span> NSF CAREER Award (#1848286) </div>
                    <div><span className="bold"> Contact: </span> hello@credictscoredesimulator.org </div>
                </div>
                <div className = "footer-text-3">© All rights reserved | Last updated: December 8, 2022 </div>
            </div>
        );
    }

} export default Footer; 