import React from 'react';
import ReactDOM from 'react-dom';
import './ScoreDisplay.css';
import Gauge from 'react-svg-gauge';
import App from '../App';

class ScoreDisplay extends React.Component{
    constructor(props){
        super(props);
    }

    getColor = () => {
        if (this.props.score < 630){
            return "#db2e0f";
        }

        if (this.props.score < 690){
            return "#f5b60a";
        }

        if (this.props.score < 720){
            return "#a3d69f";
        }

        return "#35a82c";
    }

    render(){
        return(
            <div className = "score-housing">
                <Gauge className = "score-gauge" value={this.props.score} color={this.getColor()} min={300} max={850} width={200} height = {160} label={this.props.label} />
            </div>
        )
    }
} export default ScoreDisplay;