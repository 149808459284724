import React from 'react';
import './Choices.css';

class Choices extends React.Component{
    constructor(props){
        super(props);
        this.onOptionClick = this.props.onOptionClick.bind(this);
    }

    options = this.props.options; 

    displayedOptions = this.options.map((option) =>
    <div className = "option-housing" 
    onClick={() => this.onOptionClick(option.selectKey)}>
        <div className = "option-icon-housing">
            {option.icon}
        </div> 
        <div className = "option-title">
            {option.title}
        </div>
    </div>
    );

    render(){
        return(
            <div className = "row-of-options">
                {this.displayedOptions}
            </div>
        )
    }


}export default Choices; 
