import React from 'react';
import './FAQs.css';

class FAQs extends React.Component{
    constructor(props){
        super(props);
    }

    faqList = [
        {
            title: "How does the Credit Score Desimulator™ work?",
            text: "The Credit Score Desimulator™ starts with a credit score and lets you explore how changing information and personal characteristics could affect your score. Of course, it’s all hypothetical; but so are the actual scoring simulators. Simulating these changes won't actually affect your score or credit report."
        }, 
        {
            title: "How do I use the Desimulator™?",
            text: "You can select any of the alternative scoring factors to see how they affect your score. Different factors have different inputs. Some require you to click afield, others ask you to move a slider or enter information. However, you can only select one factor at a time for better accuracy."
        },
        {
            title: "Are these changes guaranteed?",
            text: "The Credit Score Desimulator™ can help you dig into some scenarios, but it's not meant to predict or guarantee any future score changes. (Incidentally, this is exactly what the actual Credit Score Simulators say in their FAQ.)"  
        }, 
        {
            title: "What scoring model is the Credit Score Desimulator™ based on?",
            text: "The Credit Score Desimulator™ is based on our own proprietary formula that is based on data from a range of studies to make informed predictions. Keep in mind that scores change in different ways based on what scoring model is being used. For further information, click on the (i) next to the scoring factor."
        },
        {
            title: "What is the purpose of the Credit Score Desimulator™?",
            text: "What is the purpose of a Credit Score Simulator? Many factors presented in the Credit Score Desimulator™ are really things you can't change in your life. But by exploring how your credit score could potentially shift through unexpected events and demographic changes, we hope you'll discover the issues around allowing proprietary algorithms to dictate human decisions without explanation. You can read more about the dilemmas and issues surrounding the credit score industry in the accompanying essay."
        },
        {
            title: "How were these FAQ compiled?",
            text: "These FAQ were modelled after those of actual simulators. Interestingly, we did not have to change a lot."
        }
    ]

    faqsDisplayed = this.faqList.map((faq) =>
        <div>
            <div className = "faq-title">{faq.title}</div>
            <div className = "faq-text">{faq.text}</div>
        </div>
    )

    render(){
        return(
            <div className = "faqs-container">
                FAQs
                {this.faqsDisplayed} 
            </div>
        );
    }
} export default FAQs