import React from 'react';
import './Slider.css';
import Slider from '@material-ui/core/Slider';

class SliderPanel extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        }

    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
        this.props.storeValue(newValue);
      };


    valuetext = (value) => {
        return `$${value}`;
    }

    
    render() {
        console.log(this.state.value)
        return (
            <div className = "optionSlider">
                <div className = "sliderMin">{this.props.min}</div>
                <Slider 
                    value={this.state.value} 
                    onChange={this.handleChange} 
                    getAriaValueText={this.valuetext} 
                    aria-labelledby="continuous-slider" 
                    valueLabelDisplay="auto" 
                    min = {this.props.min}
                    max = {this.props.max}
                    />
                <div className = "sliderMax">{this.props.max}</div>
            </div>
        )
    }
} export default SliderPanel; 