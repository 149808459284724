import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import './xButton.css';

class xButton extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
        <div className = 'x-button-holder'>
            <div className="x-button" onClick={() => this.props.close()} >
                <FontAwesomeIcon icon={faTimesCircle} />
                <span className="tooltiptext">Close</span>
            </div>
        </div>

        );
    }
} export default xButton; 

