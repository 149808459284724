import React from 'react';
import './FactorBox.css';
import Factor from '../Factor/Factor.js';

/*=============================
    Constants
==============================*/



class FactorBox extends React.Component{
    constructor(props){
        super(props);
        this.onFactorClick = this.props.onFactorClick.bind(this);
    }

   

    /*=============================
    Functions
    ==============================*/

    /*Render a Factor*/
    renderFactor(i, j){
        return(
            <Factor 
                icon = {this.props.factorsList[i].factors[j].icon}
                title = {this.props.factorsList[i].factors[j].title}
                onClick={() => this.props.onFactorClick(i, j)}
            ></Factor>
        );
    }

    /*Render a Section*/
    renderSection(i){
        var sectionFactors = new Array(this.props.factorsList[i].factors.length).fill(null);
        for (var j = 0; j < this.props.factorsList[i].factors.length; j++) {
            sectionFactors[j] = this.renderFactor(i, j);
        } 
        return(
            <div className = "factor-box-holder">
                <div className="what-might">
                    {this.props.factorsList[i].title}
                </div>
                <div className="factor-housing">
                    {sectionFactors}
                </div>
            </div>
        );
    }




    /*=============================
    Render the Display
    ==============================*/

    render(){
        /*Creating all sections*/
        var sections = new Array(this.props.factorsList.length).fill(null);
        for (var i = 0; i < this.props.factorsList.length; i++) {
            sections[i] = this.renderSection(i);
        }
        return(
            <div>{sections}</div>
        );
    }

}export default FactorBox; 